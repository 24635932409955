import logo from './logo.svg'
import blob1 from './blob1.png'
import blob2 from './blob2.png'
import blob3 from './blob3.png'
import './App.css'

const App = () => {
	return (
		<div className="App" >
			<div className="logo">
				<img src={ logo } alt="" />
			</div>
			<div className="blobs">
				<img src={ blob1 } alt="" className="blob1" />
				<img src={ blob3 } alt="" className="blob3" />
				<img src={ blob2 } alt="" className="blob2" />
			</div>
		</div>
	)
}

export default App
